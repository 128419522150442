<!--
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-03-22 09:55:48
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-24 10:02:26
-->
<template>
  <div class="home">
    <div class="loading" v-if="!mapFinished">
      <div style="margin-bottom: 70px">
        <img src="@/assets/loading.gif" />
        <p>地图加载中...</p>
      </div>
    </div>
    <div class="home__container">
      <div class="container">
        <div id="mapContainer"><!--地图--></div>
      </div>
      <div class="opts">
        <!--当前位置-->
        <div class="icon dang">
          <img src="@/assets/dang.png" alt="" />
        </div>
      </div>
    </div>
    <!-- topLayer -->
    <div class="top_layer"></div>
    <div class="point_nav_content">
      <div class="display-flexbox mlp15 mrp15">
        <div class="center mtp10 trun_way">
          <div class="mtp5 cFEB224 distance">{{ distance }}</div>
          <div>
            <img
              class="straight_line"
              style="display: block; width: 40px; height: 40px; margin-top: 5px"
              :src="straightImg"
            />
          </div>
        </div>
        <div class="flexbox-children display-flexbox mtp15 mlp5">
          <div class="mlp10">
            <div class="fz14 time">{{ time }}</div>
            <div class="fz24 mtp15 nav_title title">{{ title }}</div>
          </div>
        </div>
        <div>
          <div class="tar mtp10 navigation_close">
            <img class="imgwh2" src="@/assets/navigation_close_nor.png" />
          </div>
          <div class="tar mtp25">
            <i class="button icon_nav_bitmap"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Loading } from 'vant'
// Toast,
import mixinsMap from '@/utils/map'

export default {
  name: 'guideIndex',
  mixins: [mixinsMap],
  components: {
    Loading,
  },
  data() {
    return {
      currentPoi: null,
      routerInfo: null,
      POIStyleObj: {},
      navigateObj: null,
      deviateNum: 0,
      handleTimer: 0,
      redriving: false,
      soundObj: null,
      drivingObj: null,
      cInfo: {},
      straightImg: null,
      distance: '',
      title: '',
      time: '',
    }
  },
  created() {
    this.wxConfig()
    const routerInfo = sessionStorage.getItem('state') || null
    this.routerInfo = routerInfo && JSON.parse(routerInfo)
  },
  mounted() {
    this.importJmapJS()
  },
  beforeDestroy() {
    this.map = null
    clearInterval(this.time)
  },
  methods: {
    dealWithMapInfo() {
      this.map.on('EVENT_MAP_INIT_FINISH', () => {
        this.mapFinished = true
        this.map.mapAdaptDiv()
        this.map.clearCustomPoiList()
        this.map.clearPoiSearch()
        this.map.hideStopDetails()
        // this.getLocationFromWx()
        this.time = setInterval(() => {
          this.throttle(this.getLocationFromWx())
        }, 2000)
        this.soundObj = JMap.getSoundObj()
        this.drivingObj = new JMap.Driving({ map: this.map })
        // setInterval(() => {
        //   this.getLocationFromWx()
        //   const cur = this.map.getCarPos()
        //   const { lng, lat } = cur
        //   const gpsInfo = {
        //     lnglat: { lng: lng + 20, lat: lat },
        //     angle: 0,
        //     speed: 5,
        //   }
        //   this.map.setGpsInfo(gpsInfo, 1)
        //   this.map.getCarPos()
        // }, 2000)
        if (this.routerInfo) {
          this.map.showRouteInfo(this.routerInfo)
          //移动开始
          this.navigateObj = JMap.Navigate.new(this.routerInfo, {
            timeOut: 2000,
            map: this.map,
          })
          this.navigateObj.start(0)
          JMap.on('J_EVENT_NAVIGATE', this.simulateCallback)
        }
      })
    },
    simulateCallback(simulateMsg, rav1, rav2) {
      console.log('simulateMsg:', simulateMsg)
      if (this.navigateObj) {
        if (simulateMsg == 'SIMULATE_STATE') {
          if (this.navigateObj.IsDeviate() !== 0) {
            this.deviateNum = 0
            this.remainTitle(rav1, rav2)
          } else {
            this.deviateNum++
            if (!this.redriving) {
              this.redriving = true
              this.deviateNum = 0
              if (!this.handleTimer) {
                this.yawRoute(this.navigateObj.getRemindInfo())
                this.handleTimer = setTimeout(this.timerRedriving, 30 * 1000)
              }
            }
          }
        } else if (simulateMsg == 'SIMULATE_END') {
          this.simulateEnd()
        }
      }
    },
    // 转弯的图标显示
    remainTurn(turn) {
      let imageSrc = ''
      switch (turn) {
        case 0:
          imageSrc = require('@/assets/guide/straight_line.png')
          break
        case 1:
          imageSrc = require('@/assets/guide/straight_line.png')
          break
        case 2:
          imageSrc = require('@/assets/guide/turn_around.png')
          break
        case 3:
          imageSrc = require('@/assets/guide/turn_left.png')
          break
        case 4:
          imageSrc = require('@/assets/guide/turn_right.png')
          break
        case 5:
          imageSrc = require('@/assets/guide/left_front.png')
          break
        case 6:
          imageSrc = require('@/assets/guide/right_front.png')
          break
        case 7:
          imageSrc = require('@/assets/guide/left_rear.png')
          break
        case 8:
          imageSrc = require('@/assets/guide/right_rear.png')
          break
        default:
      }
      return imageSrc
    },
    //   导航中
    remainTitle(remain, pathtotal) {
      if (remain != 0 && pathtotal != 0) {
        this.straightImg = this.remainTurn(remain.turn)
        this.time = this.getNextTime(remain.nextSceStopTime)
        this.distance = this.getDis(remain.remainTurnDis)
        this.title = remain.name
        console.log('nextTime:', this.time, 'distance:', this.distance)
        //   $('.time').html(nextTime)
        //   $('.nav_title').html(remain.name)
        //   $('.distance').html(getDis(remain.remainTurnDis))
        //   $('.destination,.distance').show()
      }
    },
    //   偏航
    yawRoute(yawResult) {
      const text = '您已偏离路线，已为您重新规划'
      this.soundObj.play(text, 'TYPE_SOUND_GUIDE', false)
      const dri_opt = {
        type: 10,
        planCond: {
          tourTime: 0,
          pubTrans: 0,
          privTrans: 0,
        },
        fileName: '',
        deviate: yawResult,
        armList: {
          armCount: 0,
          poiList: [],
        },
      }
      this.drivingObj.search(dri_opt, (status, searchResult) => {
        if (status == 'complete') {
          if (this.navigateObj != 0) {
            this.navigateObj.stop()
          }
          this.map.showRouteInfo(searchResult.guidePath[0])
          this.navigateObj = this.map.Navigate.new(searchResult.guidePath[0], {
            timeOut: 2000,
            map: this.map,
          })
          this.navigateObj.start(0)
        }
        if (this.handleTimer) {
          this.redriving = false
          clearTimeout(this.handleTimer)
          this.handleTimer = 0
        }
      })
    },
    //   导航结束
    simulateEnd() {
      const soundDuide = parseInt(this.soundObj.getState('TYPE_SOUND_GUIDE'))
      if (soundDuide == 1) {
        this.map.on('J_EVENT_AUDIO', (palyMsg, voiceState) => {
          if (voiceState == 'PLAY_END') {
            this.map.off('J_EVENT_AUDIO')
            this.$router.back()
          }
        })
      } else {
        this.$router.back()
      }
    },
    getDis(dis) {
      let distance
      if (dis > 1000) {
        distance = '' + (dis / 1000).toFixed(1) + 'km'
      } else {
        distance = '' + dis + 'm'
      }
      return distance
    },
    getNextTime(time) {
      let nextTime = ''
      const minutes = this.getMinutes(time)
      const hours = this.getHours(time)
      if (hours == 0) {
        if (minutes == '0') {
          nextTime = '约1分钟'
        } else {
          nextTime = '约' + minutes + '分钟'
        }
      } else {
        hours = hours
        minutes = minutes / 60
        const total = (hours + minutes).toFixed(1)
        nextTime = '约' + total + '小时'
      }
      return nextTime
    },
    getMinutes(alltime) {
      const leave2 = alltime % 3600
      return Math.floor((leave2 + 30) / 60)
    },
    getHours(alltime) {
      return Math.floor(alltime / 3600)
    },
    timerRedriving() {
      this.redriving = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/guide.scss';
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      display: block;
      width: 90px;
      height: 90px;
    }
    p {
      font-size: 16px;
      color: #ccc;
    }
  }
  &__container {
    width: 100%;
    height: 100%;
  }
  .choice {
    width: 100%;
    height: 0.8rem;
    padding: 0.15rem 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: scroll;
    .con {
      display: block;
      width: 590px;
      height: 0.5rem;
      font-size: 0;
      padding: 0 1rem 0 0.2rem;
      overflow: hidden;
      white-space: nowrap;
      li {
        display: inline-block;
        font-size: 0.25rem;
        line-height: 0.5rem;
        padding: 0 0.2rem;
        margin: 0 0.2rem;
        color: #000;
        font-size: 0.28rem;
        border: 0;
        border-radius: 0.25rem;
      }
      .on {
        font-size: 0.3rem;
        background-color: #fd7d6f;
        color: #fff;
      }
    }
  }
  .search {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.18rem;
    height: 0.8rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .container {
    position: absolute;
    color: #000;
    border: 0px solid #000;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  #mapContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .lu {
    width: 2.14rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 1rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .bak {
    width: 1.5rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 2rem;
  }
  .opts {
    position: absolute;
    right: 0.1rem;
    bottom: 1rem;
    .icon {
      width: 0.88rem;
      height: 0.88rem;
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .cover {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .shaiCon {
      width: 100%;
      height: auto;
      h5 {
        margin: 0.2rem 0;
        padding-left: 0.2rem;
        text-align: center;
        font-weight: normal;
        font-size: 0.3rem;
      }

      .con {
        width: 5.5rem;
        height: 5.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        padding: 0.4rem;
        box-sizing: border-box;
        margin: -2.75rem 0 0 -2.75rem;
        z-index: 99;
      }
      .close {
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        right: 0.3rem;
        top: 0.4rem;
        img {
          display: block;
          width: 100%;
        }
      }
      .item {
        float: left;
        width: 25%;
        padding: 0.2rem;
        box-sizing: border-box;
        height: auto;
        overflow: hidden;
        text-align: center;
        font-size: 0.24rem;
        white-space: nowrap;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .three {
      .close {
        top: 1.6rem;
      }
      .item {
        width: 33.33%;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .lines {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      /*height: 5rem;*/
      background: #fff;
      .con {
        height: 3.6rem;
        overflow-y: scroll;
        span {
          float: right;
          color: #999;
          i {
            color: rgb(253, 125, 111);
            margin-right: 0.05rem;
            font-style: normal;
          }
        }
      }
      .item {
        border-bottom: solid 1px rgb(237, 237, 237);
        height: 1.2rem;
        overflow: hidden;
        font-size: 0.28rem;
        line-height: 1.2rem;
        padding: 0rem 0.3rem;
        color: rgb(102, 102, 102);
      }
      .title {
        color: rgb(51, 51, 51);
        font-size: 0.36rem;
        height: 1.2rem;
        line-height: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.3rem;
        border-bottom: solid 1px #ededed;
        img {
          float: left;
          display: block;
          width: 0.39rem;
          height: 0.35rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.poi {
  display: inline-block;
  width: auto;
  // position: relative;
  z-index: 10;
  .poi-click {
    width: auto;
    padding: 0.2rem 0.2rem;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0.2rem;
    font-size: 0;
    position: absolute;
    margin-top: -0.3rem;
    margin-left: -1.5rem;
    display: none;
    flex-direction: row;
    top: -30rem;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 0.18rem solid transparent;
      border-right: 0.18rem solid transparent;
      border-top: 0.26rem solid #fff;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -0.09rem;
    }
  }
  .titleimg {
    width: 1rem;
    height: 1rem;
    margin: 0.1rem 0.2rem 0 0.1rem;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  .titleimg,
  .TTimg {
    // float: left;
    display: inline-block;
  }
  .imgtitle {
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
    .title {
      padding-left: 0.15rem;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.28rem;
    width: 1.68rem;
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    overflow: hidden;
    color: #fff;
    border-radius: 0.34rem;
    margin: 0 0.05rem;
    img {
      display: inline-block;
      width: 0.23rem;
      height: 0.23rem;
      margin: 0 0.1rem 0 0.2rem;
      vertical-align: middle;
    }
  }
  .spot {
    color: #999999;
    font-size: 0.28rem;
    padding-left: 0.15rem;
    white-space: nowrap;
  }
  .bottoms {
    display: flex;
    flex-direction: row;
  }
  .voice {
    background-color: #289cff;
  }
  .gothere {
    background-color: #ffb128;
  }
}
.bottom-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  .routeline {
    display: block;
    background: #fff;
    padding: 0.4rem 0.3rem;
    font-size: 0;
    .title {
      font-size: 0.34rem;
      height: 0.6rem;
      overflow: hidden;
      line-height: 0.6rem;
      text-align: left;
      color: #aaaaaa;
      span {
        font-size: 0.3rem;
        color: #aaa;
        float: right;
        display: inline-block;
        padding-right: 0.1rem;
        margin-top: -0.1rem;
      }
    }
    .item {
      display: inline-block;
      line-height: 0.8rem;
      font-size: 0.36rem;
    }
    .time {
      margin-right: 0.4rem;
    }
    .spendTime,
    .distance {
      display: inline-block;
      line-height: 0.8rem;
    }
    .guide {
      float: right;
      display: block;
      width: 2.5rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #ea8677;
      color: #fff;
      text-decoration: none;
      border: 0;
      border-radius: 0.5rem;
      font-size: 0.34rem;
      box-sizing: border-box;
      text-align: center;
    }
  }
  .sPointContainer {
    display: block;
    height: 0.8rem;
    position: relative;
    background: #fff;
    padding-left: 1rem;
    font-size: 0;
    .play,
    .close {
      position: absolute;
      line-height: 0.6rem;
    }
    .play {
      width: 0.6rem;
      height: 0.6rem;
      left: 0.2rem;
      top: 0.1rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: inline-block;
      font-size: 0.28rem;
      color: #000;
      line-height: 0.8rem;
      padding-right: 0.5rem;
      position: relative;
    }
    .icon-right {
      position: absolute;
      right: 0;
      top: 0.3rem;
      width: 0.13rem;
      height: 0.25rem;
      background: url('../../assets/icon-right.png') right center no-repeat;
    }
    .close {
      right: 0.2rem;
      top: 0.35rem;
      width: 0.15rem;
      height: 0.15rem;
      padding: 0.3rem 0.1rem;
      transform: translateY(-40%);
    }
  }
}
</style>